import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";



import headerImg1 from "../assets/img/inicialbase-img1.png";
import headerImg2 from "../assets/img/inicialbase-img2.png";
import headerImg3 from "../assets/img/inicialbase-img3.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import backdrop from "../assets/img/backdrop_base001.png";
import img1 from "../assets/img/gl015.png";
import img2 from "../assets/img/gl016.png";
import img3 from "../assets/img/gl015.png";


import imgBot from "../assets/img/comicstripe03.png";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "where Technology Crafts Magic.", "blending AI and Creativity", "where mysteries Await! Join Us in Crafting an AI-Assisted Epic" ]
  const period = 2000;


  // New state for the dynamic images
  const images = [img1, img2, img3];
  const [currentIndex, setCurrentIndex] = useState(0);
  
  
  // New effect for changing the dynamic images
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [currentIndex]);
  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])



  
  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }


  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
        <Col md={6}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Wellcome to Spellwatch project.</span>
                <h1 className="header-text">{`This is  `} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "where Technology Crafts Magic.", "blending AI and Creativity", "where mysteries Await! Join Us in Crafting an AI-Assisted Epic"  ]'><span className="wrap">{text}</span></span></h1>
                  <div className="container-bx wow zoomIn">
                    <p>Connecting people through technology and storytelling, this project builds an immersive digital cosmos. It's not just about comics and games, but forging emotional bonds. With every page turned or screen tapped, we create resonant experiences, nurture relationships, and foster a thriving community.</p>
                  </div>
                  
              </div>}
            </TrackVisibility>
          </Col>
          <Col md={6} style={{ minHeight: '620px', position: 'relative', overflow: 'hidden' }}>
  <div style={{
    position: 'relative', 
    backgroundImage: `url(${backdrop})`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    height: '100%',
  }}>
    {images.map((img, index) => (
      <img
        key={index}
        src={img}
        style={{ position: 'absolute', top: '0', left: '0' }} // Assuming you want images to start from top left
        alt={`Dynamic Image ${index}`}
        className={currentIndex === index ? 'fadeInLeft' : 'fadeOutRight'}
      />
    ))}
  </div>
</Col>

         

        </Row>

        <Row className="center-image">
  <img src={imgBot} />
</Row>

      </Container>



      {/* New row with dynamic images */}
      {/* <Container>
  <Row>
          <Col xs={12} md={6} xl={5}>
              <TrackVisibility>
  {({ isVisible }) =>
    <div className="image-container">
      <img src={headerImg1} alt="Static Img" className={isVisible ? "" : ""} />
      <img src={headerImg2} alt="Moving Img" className={isVisible ? "moving-image" : ""} />
      <img src={headerImg3} alt="Fading Img" className={isVisible ? "fading-image" : ""} />
    </div>}
</TrackVisibility>
</Col>
    <Col md={6}>
    <div className="container-bx wow zoomIn">
                    <p>At the heart of this endeavor is the desire to connect people. With every character arc and game level, there is an opportunity to forge bonds and stimulate emotion. This project isn't just about comics or gaming; it's about crafting an experience that will engage and resonate with its audience on a deeper level. It's about building a universe where stories matter, where each turn of the page or tap on the screen brings us closer. This venture, sparked by a love for technology and storytelling, aims to create a digital cosmos where relationships are nurtured, emotions are stirred, and community thrives.</p>
                  </div>
                  </Col>
  </Row>
</Container> */}

    </section>
  )
}
