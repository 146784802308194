import { useState } from 'react';
import { Card, Modal, Button, Col,Row } from 'react-bootstrap';
import ReactPlayer from 'react-player'


export const ProjectCardWithModal = ({ title, cardDescription, cardImgUrl,insideVidUrl , insideImgUrl, insideDescription }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx" onClick={handleShow}>
        <img src={cardImgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{cardDescription}</span>
        </div>
      </div>

      <Modal className="modal-90w" show={show} onHide={handleClose}>
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'row' }}>

  <div className="modal-text">
    <p>{insideDescription}</p>
  </div>
  <div className="video-wrapper">
  <ReactPlayer 
  url={insideVidUrl} 
  playing 
  controls 
  loop 
  width='100%' 
  height='100%' 
/>
    </div>
</Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}
