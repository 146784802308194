import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logositeimg.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import img1 from "../assets/img/Untitled-4.png";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
        <div className="newsletter-bx wow slideInUp">

 
<Container>
  <Row className="aligh-items-center content-row">

    <Col md={6} className="content-column">
<div className="enlarged-image">
   
</div>
    </Col>
    <Col md={6} className="content-column">
      < div className="container-bx wow zoomIn">
        <p>At the heart of this endeavor is the desire to connect people. With every character arc and game level, there is an opportunity to forge bonds and stimulate emotion. This project isn't just about comics or gaming; it's about crafting an experience that will engage and resonate with its audience on a deeper level. It's about building a universe where stories matter, where each turn of the page or tap on the screen brings us closer. This venture, sparked by a love for technology and storytelling, aims to create a digital cosmos where relationships are nurtured, emotions are stirred, and community thrives.</p>
      </div>
    </Col>
  </Row>
</Container>

        </div>
          <Col size={12} sm={6}>
          <img src={logo} alt="Logo" className="footer-logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="#"><img src={navIcon1} alt="Icon" /></a>
              <a href="#"><img src={navIcon2} alt="Icon" /></a>
              <a href="#"><img src={navIcon3} alt="Icon" /></a>
            </div>

            <p>Copyright 2023. All Rights Reserved</p>
            <p>All images generated with AI tools and are only concept art</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
