import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { ProjectCardWithModal } from "./ProjectCardWithModal";
import artImg1 from "../assets/img/art-Design-img1.png";
import artInsideImg1 from "../assets/img/story-inside-img1.png";
import storyInsideImg1 from "../assets/img/story-inside-img1.png";
import gamedevInsideImg1 from "../assets/img/story-inside-img1.png";

import video001 from '../assets/vid/001.mp4'
import video002 from '../assets/vid/002.mp4'
import video003 from '../assets/vid/003.mp4'


import storyImg1 from "../assets/img/story-img1.png";
import gamedevImg1 from "../assets/img/project-img3.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import projImg1 from "../assets/img/project-img2.png";

import projImg2 from "../assets/img/projAe001.png";
import projImg3 from "../assets/img/projAe002.png";
import projImg4 from "../assets/img/projAe003.png";
import projImg5 from "../assets/img/projAe004.png";
import projImg6 from "../assets/img/projAe005.png";
import projImg7 from "../assets/img/projAe006.png";



import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { Columns } from "react-bootstrap-icons";





export const Projects = () => {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const ccreation = [
    {
      title: "Art and Design",
      cardDescription: "Design & Development",
      insideVidUrl: video001,
      cardImgUrl: artImg1,
      insideImgUrl: artInsideImg1,
      insideDescription: "Leveraging AI technologies, this project uniquely blends art and design to visually narrate stories. The aesthetic, curated to augment immersion, is borne from models on Civitai.com and Stable Diffusing UI. Despite a non-conventional artistic process, passion for quality content fuels this exploration of artistic styles. Conceptualized visuals evolve into final designs through iterative feedback and experimentation. This human-AI collaboration results in a unique visual experience that not only supports storytelling but showcases innovative AI usage in creative spaces."
},

    {
      title: "Story Development",
      cardDescription: "Design & Development",
      insideVidUrl: video002,
      cardImgUrl: storyImg1,

      insideImgUrl: storyInsideImg1,
      insideDescription: "Aided by AI tools like ChatGPT, the universe of the comic book series and mobile game unfolded, featuring a magical world where battle golems aren't mere legends but everyday reality. These golems form the backbone of epic tales that span centuries, from the era of the Spellwatch - protectors of the realm, to present-day heroes. All elements, from the magic system to the diverse characters and plot lines, weave together to create a rich, ever-evolving tapestry of storytelling.",

    },
    {
      title: "Game Development",
      cardDescription: "Design & Development",
      insideVidUrl: video003,
      cardImgUrl: gamedevImg1,
      insideImgUrl: gamedevInsideImg1,
      insideDescription: "Born from a love for Mega Man Network Battle and Medabots, the game concept has grown and transformed over the years. Initially, its key feature was the interchangeable robot parts and grid-based battle system. The journey of learning programming languages spurred the evolution of the game, transforming the robot concept into wooden golems in a magical world, inspired by the Thaumcraft mod from Minecraft. AI tools, including Stable Diffusion and ChatGPT, have been instrumental in expediting the development process, enabling a swift evolution of game mechanics and elements.",

    }
  ];

  const cengagement = [
      {
        title: "Business Startup",
        description: "Design & Development",
        imgUrl: projImg1,
      },

  ];
      const financial = [
        {
          title: "Business Startup",
          description: "Design & Development",
          imgUrl: projImg1,
        },



  ];

  return (
    <section className="project" id="projects">
    <Container>
      <Row>
        <Col size={12}>
          <TrackVisibility>
            {({ isVisible }) =>
            <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
              <h2>Project</h2>
              <p>The three pillars of our project - AI-powered Content Generation, Audience Engagement, and Project Viability - represent our commitment to innovation, community, and sustainability. Each component is distinct yet interconnected, collectively shaping the unique essence of our project. Explore the details to gain insight into our vision, methodology, and the journey ahead.</p>
              <Tab.Container id="projects-tabs" defaultActiveKey="first">
                <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                  <Nav.Item>
                    <Nav.Link eventKey="first">Content Generation</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Audience Engagement</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Project Viability</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                  <Tab.Pane eventKey="first">
                    <Row>
                      {
                        ccreation.map((project, index) => {
                          return (
                            <ProjectCardWithModal
                              key={index}
                              {...project}
                              />
                          )
                        })
                      }
                    </Row>
                  </Tab.Pane>


                  {/* Segunda TAB */}
                  <Tab.Pane eventKey="second">
                  <div style={{ textAlign: 'center' }}>

  



</div>




                    <p>These strategies represent our commitment to engaging our supporters as we develop this exciting project. As a Patreon-backed venture, we want to make our patrons feel valued and integral to our project's success. We believe that fostering this sense of community will not only boost engagement but also aid in achieving the project's objectives and potential.</p>
                  </Tab.Pane>


                  {/* Terceira TAB */}
                  <Tab.Pane eventKey="third">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    <Row>
                      <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
  <div className="item">
    <div className="imagepp-item">
      <img src={projImg2} alt="Image" className="responsive-image" />
      </div>
      <p style={{ textAlign: 'justify' }}>We plan to create a Patron-only forum where fans can engage in lively discussions about the project. They can share their theories, favorite moments, fan art, and even collaborate on fanfiction. This exclusive forum will be a breeding ground for creativity and engagement.</p>
  </div>
  <div className="item">
      <p style={{ textAlign: 'justify' }}>Our patrons will get exclusive access to our private social media groups on platforms such as Facebook and Instagram. These groups will offer direct lines of communication to us, fostering a stronger connection with the project and creating a sense of community.</p>
    <div className="imagepp-item">
      <img src={projImg3} alt="Image" className="responsive-image" />
     </div>
  </div>
  <div className="item">
    <div className="imagepp-item">
      <img src={projImg4} alt="Image" className="responsive-image" />
    </div>
    <p style={{ textAlign: 'justify' }}>We will introduce a reward system for our patrons, offering badges, ranks, or unique privileges based on their level of engagement with the community. Our patrons will be able to participate in unique challenges and quests, creating an exciting, competitive atmosphere.</p>
    </div>

    <div className="item">
  <p style={{ textAlign: 'justify' }}>Our patrons will get exclusive access to our private social media groups on platforms such as Facebook and Instagram. These groups will offer direct lines of communication to us, fostering a stronger connection with the project and creating a sense of community.</p>
    <div className="imagepp-item">
      <img src={projImg5} alt="Image" className="responsive-image" />
     </div>
     </div>
     <div className="item">
    <div className="imagepp-item">
      <img src={projImg6} alt="Image" className="responsive-image" />
    </div>
    <p style={{ textAlign: 'justify' }}>We will introduce a reward system for our patrons, offering badges, ranks, or unique privileges based on their level of engagement with the community. Our patrons will be able to participate in unique challenges and quests, creating an exciting, competitive atmosphere.</p>
    </div>
    <div className="item">
    <p style={{ textAlign: 'justify' }}>We plan to create a Patron-only forum where fans can engage in lively discussions about the project. They can share their theories, favorite moments, fan art, and even collaborate on fanfiction. This exclusive forum will be a breeding ground for creativity and engagement.</p>
    
    <div className="imagepp-item">
      <img src={projImg7} alt="Image" className="responsive-image" />
      </div>
      </div>
    </Carousel>

                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>}
          </TrackVisibility>
        </Col>
      </Row>
    </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
